<style>
.rounded-image-with-stroke {
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  width: 150px;
}

.attachment-item {
  display: inline-block;
  margin-right: 10px;
}

.attachment-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

</style>

<template>
  <div>
    <b-card no-body class="mb-0">
      <b-row class="match-height">
        <b-col lg="12">
          <b-card title="Contract Detail">
            <b-row>
              <!-- Back Button -->
              <b-col cols="6">
                <b-button type="button" variant="primary" class="mr-1" @click="goBack()">
                  <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" />
                  Back
                </b-button>
              </b-col>

              <!-- Contract Image -->
              <b-col cols="6" class="d-flex justify-content-end">
                <img v-if="contractImage" :src="contractImage" alt="Contract Image" class="rounded-image-with-stroke" />
              </b-col>

              <!-- Contract Fields -->
              <b-col cols="12">
                <div class="divider" />

                <b-form-group label="Contract ID" label-for="contractId" label-cols-md="12">
                  <b-form-input v-model="contract.contract_id" placeholder="Contract ID" readonly />
                </b-form-group>

                <b-form-group label="Agreement Reference" label-for="agreementRef" label-cols-md="12">
                  <b-form-input v-model="contract.agreement_reference" placeholder="Agreement Reference" readonly />
                </b-form-group>

                <b-form-group label="Customer" label-for="customer" label-cols-md="12">
                  <b-form-input v-model="contract.customer_name" placeholder="Customer Name" readonly />
                </b-form-group>

                <b-form-group label="Description" label-for="description" label-cols-md="12">
                  <b-form-input v-model="contract.description" placeholder="Contract Description" readonly />
                </b-form-group>

                <b-form-group label="Issued Date" label-for="issuedDate" label-cols-md="12">
                  <b-form-input v-model="contract.issued_date" placeholder="Issued Date" readonly />
                </b-form-group>

                <b-form-group label="Start Date" label-for="startDate" label-cols-md="12">
                  <b-form-input v-model="contract.start_date" placeholder="Start Date" readonly />
                </b-form-group>

                <b-form-group label="End Date" label-for="endDate" label-cols-md="12">
                  <b-form-input v-model="contract.end_date" placeholder="End Date" readonly />
                </b-form-group>

                <b-form-group label="Issued By" label-for="issuedBy" label-cols-md="12">
                  <b-form-input v-model="contract.issued_by" placeholder="Issued By" readonly />
                </b-form-group>

                <b-form-group label="Contract Value" label-for="contractValue" label-cols-md="12">
                  <b-form-input v-model="contract.contract_value" placeholder="Contract Value" readonly />
                </b-form-group>

                <b-form-group label="Status" label-for="status" label-cols-md="12">
                  <b-form-input v-model="contract.status" placeholder="Contract Status" readonly />
                </b-form-group>

                <!-- Display Multiple Attachments -->
                <b-form-group label="Attachments" label-cols-md="12">
                  <div v-if="attachments && attachments.length">
                    <div v-for="(file, index) in attachments" :key="index" class="attachment-item">
                      <a :href="file.url" target="_blank">
                        <img v-if="isImage(file.url)" :src="file.url" :alt="'Attachment ' + (index + 1)" />
                        <b-badge v-else variant="info">{{ file.name }}</b-badge>
                      </a>
                    </div>
                  </div>
                  <p v-else>No Attachments</p>
                </b-form-group>

              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
    BRow, BCol, BFormGroup, BFormInput, BButton, BCard, BBadge,
} from "bootstrap-vue"
import axios from "axios"
import { ref } from "@vue/composition-api"

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BBadge,
  },
  setup() {
    const contract = ref({})
    const contractImage = ref("")
    const attachments = ref([])

    // Helper function to check if the file is an image
    const isImage = url => {
      return /\.(jpg|jpeg|png|gif|bmp)$/i.test(url)
    }

    // Function to load contract details
    const getContractDetails = async () => {
      const contractId = localStorage.getItem("contractId") // Assuming contractId is stored in localStorage

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_CONTRACT_GET_DETAILS}/${contractId}`,
        )
        if (response.status === 200 && response.data) {
          const contractData = response.data
          contract.value = contractData
          contractImage.value = `${process.env.VUE_APP_API_URL}upload/${contractData.contract_image}`

          // Assuming attachments are provided in an array format in the API response
          if (contractData.attachments && contractData.attachments.length > 0) {
            attachments.value = contractData.attachments.map(file => ({
              url: `${process.env.VUE_APP_API_URL}upload/${file.file_name}`,
              name: file.file_name,
            }))
          }
        }
      } catch (error) {
        console.error("Error fetching contract details:", error)
      }
    }

    // Function to navigate back
    const goBack = () => {
      this.$router.push({ name: "apps-contracts-list" })
    }

    return {
      contract,
      contractImage,
      attachments,
      getContractDetails,
      goBack,
      isImage,
    }
  },
  mounted() {
    this.getContractDetails()
  },
}
</script>
